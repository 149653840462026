dmx.Component('tagify', {

  extends: 'form-element',

  initialData: {
    items: [],
    values: [],
  },

  attributes: {
    settings: {
      type: Object,
      default: {},
    },

    data: {
      type: Array,
      default: null,
    },

    tagValue: {
      type: String,
      default: '$value',
    },

    tagText: {
      type: String,
      default: '$value',
    },

    tagSecondary: {
      type: String,
      default: null,
    },

    tagImage: {
      type: String,
      default: null,
    },

    tagClass: {
      type: String,
      default: null,
    },

    tagCount: {
      type: String,
      default: null,
    },

    tagReadonly: {
      type: String,
      default: null,
    },

    nocustom: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    delimiters: {
      type: String,
      default: ',',
    },

    duplicates: {
      type: Boolean,
      default: false,
    },

    noinput: {
      type: Boolean,
      default: false,
    },

    maxTags: {
      type: Number,
      default: Infinity,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: null,
    },

    notrim: {
      type: Boolean,
      default: false,
    },

    noautocomplete: {
      type: Boolean,
      default: false,
    },

    keepInvalid: {
      type: Boolean,
      default: false,
    },

    skipInvalid: {
      type: Boolean,
      default: false,
    },

    minChars: {
      type: Number,
      default: 2,
    },

    caseSensitive: {
      type: Boolean,
      default: false,
    },

    maxItems: {
      type: Number,
      default: 10,
    },

    noFuzzySearch: {
      type: Boolean,
      default: false,
    },

    noAccentedSearch: {
      type: Boolean,
      default: false,
    },

    dropdownPosition: {
      type: String,
      default: 'all',
      enum: ['all', 'text', 'input'],
    },

    highlightFirst: {
      type: Boolean,
      default: false,
    },

    noCloseOnSelect: {
      type: Boolean,
      default: false,
    },

    pattern: {
      type: String,
      default: null,
    },

    dragsort: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    addEmptyTag () {
      this._tagify.addEmptyTag();
    },

    addTags (tags, clear, skipInvalids) {
      this._tagify.addTags(tags, clear, skipInvalids);
      this._updateData();
    },

    removeTags (tags) {
      this._tagify.removeTags(tags);
      this._updateData();
    },

    removeAllTags () {
      this._tagify.removeAllTags();
      this._updateData();
    },
  },

  events: {
    change: Event,
    add: Event,
    remove: Event,
    invalid: Event,
    input: Event,
    focus: Event,
    blur: Event,
    noresults: Event,
  },

  init (node) {
    this._formdataHandler = this._formdataHandler.bind(this);
    this._updateData = this._updateData.bind(this);

    this._transformTag = tag => {
      delete tag.class;
      delete tag.count;
      delete tag.image;
      delete tag.__dmx;
      delete tag.__item;

      const found = this._whitelist().find(data => data.value == tag.value || data.label == tag.value);

      if (found) {
        Object.assign(tag, found);
      }
    };

    dmx.Component('form-element').prototype.init.call(this, node);

    if (node.form) {
      node.form.addEventListener('formdata', this._formdataHandler);
    }
  },

  destroy () {
    dmx.Component('form-element').prototype.destroy.call(this);

    if (this.$node.form) {
      this.$node.form.removeEventListener('formdata', this._formdataHandler);
    }

    this._tagify.destroy();
  },

  render (node) {
    this._tagify = new Tagify(node, {
      enforceWhitelist: this.props.nocustom,
      whitelist: this._whitelist(),
      tagTextProp: 'label',
      delimiters: this.props.delimiters,
      duplicates: this.props.duplicates,
      userInput: !this.props.noinput,
      maxTags: this.props.maxTags,
      mode: this.props.mode,
      trim: !this.props.notrim,
      keepInvalidTags: this.props.keepInvalid,
      skipInvalid: this.props.skipInvalid,
      pattern: this.props.pattern && this.props.pattern.startsWith('/') ? new RegExp(this.props.pattern.replace(/^\/|\/$/, '')) : this.props.pattern,
      autoComplete: {
        enabled: !this.props.noautocomplete,
        rightKey: !this.props.noautocomplete,
      },
      dropdown: {
        enabled: this.props.minChars >= 0 ? this.props.minChars : false,
        searchKeys: ['label'],
        caseSensitive: this.props.caseSensitive,
        maxItems: this.props.maxItems,
        fuzzySearch: !this.props.noFuzzySearch,
        accentedSearch: !this.props.noAccentedSearch,
        position: this.props.dropdownPosition,
        highlightFirst: this.props.highlightFirst,
        closeOnSelect: !this.props.noCloseOnSelect,
      },
      originalInputValueFormat: tags => tags.map(tag => tag.value).join(this.props.delimiters[0]),
      transformTag: this._transformTag,
      templates: this._templates,
      ...this.props.settings,
    });

    if (this.props.nocustom && !this.props.data) {
      this._tagify.loading(true);
      this._tagify.setDisabled(true);
    } else {
      this._tagify.loading(this.props.loading);
    }

    this._tagify.setReadonly(this.props.readonly);

    this._tagify.on('change', () => this.dispatchEvent('change'));
    this._tagify.on('focus', () => this.dispatchEvent('focus'));
    this._tagify.on('blur', () => this.dispatchEvent('blur'));

    this._tagify.on('add', ({detail}) => {
      this.dispatchEvent('add', null, {
        item: detail.data.__item,
        value: detail.data.value,
        isCustom: !detail.data.__item,
      });
    });

    this._tagify.on('remove', ({detail}) => {
      this.dispatchEvent('remove', null, {
        item: detail.data.__item,
        value: detail.data.value,
        isCustom: detail.data.__item,
      });
    });

    this._tagify.on('invalid', ({detail}) => {
      this.dispatchEvent('invalid', null, {
        value: detail.data.value,
        message: detail.message,
      });
    });

    this._tagify.on('input', ({detail}) => {
      this.dispatchEvent('input', null, {
        value: detail.value,
        isValid: detail.isValid,
      });
    });

    this._tagify.on('dropdown:noMatch', ({detail}) => {
      this.dispatchEvent('noresults', null, {
        value: detail.value,
      });
    });

    this._tagify.on('change', this._updateData);
    this._tagify.on('focus', () => this.set('focused', true));
    this._tagify.on('blur', () => this.set('focused', false));

    if (this.props.dragsort) {
      this._setDragSort(true);
    }
  },

  performUpdate (updatedProps) {
    if (!this._tagify) return;

    if (updatedProps.has('dragSort')) {
      this._setDragSort(this.props.dragsort);
    }

    if (updatedProps.has('disabled')) {
      this._tagify.setDisabled(this.props.disabled);
      this.set('disabled', this.props.disabled);
    }

    if (updatedProps.has('readonly')) {
      this._tagify.setReadonly(this.props.readonly);
    }

    if (updatedProps.has('data')) {
      this._tagify.whitelist = this._whitelist();

      if (this.props.nocustom) {
        this._setValue(this.props.value || '', true);
        this._tagify.loading(this.props.loading);
        this._tagify.setDisabled(this.props.disabled);
      } else {
        this._tagify.getTagElms().forEach(tag => {
          const tagData = this._tagify.getSetTagData(tag);

          if (!tagData.__dmx) {
            const found = this._whitelist().find(data => data.value == tagData.value || data.label == tagData.value);

            if (found) {
              Object.assign(tagData, found);
              this._tagify.replaceTag(tag, tagData);
            }
          }
        });
        this._updateData();
      }
    }

    if (updatedProps.has('value')) {
      this._setValue(this.props.value, true);
    }

    if (updatedProps.has('loading')) {
      this._tagify.loading(this.props.loading);
    }
  },

  _setDragSort (enable) {
    if (this._dragsort) {
      this._dragsort.destroy();
    }

    if (enable) {
      if (window.DragSort) {
        this._dragsort = new DragSort(this._tagify.DOM.scope, {
          selector: '.' + this._tagify.settings.classNames.tag,
          callbacks: {
            dragEnd: () => this._tagify.updateValueByDOMTags()
          }
        });
      } else {
        console.warn('DragSort is not loaded');
      }
    }
  },

  _updateData () {
    const tags = this._tagify.getCleanValue();
    this.set('items', tags.map(tag => tag.__item));
    this.set('values', tags.map(tag => tag.value));
  },

  _templates: {
    wrapper (input, _s) {
      return `
        <tags
          class="${_s.classNames.namespace} ${_s.mode ? _s.classNames[_s.mode + 'Mode'] : ''} ${input.className}"
          ${_s.readonly ? 'readonly' : ''}
          ${_s.disabled ? 'disabled' : ''}
          ${_s.required ? 'required' : ''}
          ${input.hasAttribute('dmxDomId') ? `dmxDomId="${input.getAttribute('dmxDomId')}"` : ''}
          ${input.hasAttribute('style') ? `style="${input.getAttribute('style')}"` : ''}
          tabindex="-1"
        ><span
          ${!_s.readonly && _s.userInput ? 'contenteditable' : ''}
          tabindex="0"
          data-placeholder="${_s.placeholder || '&#8203;'}"
          aria-placeholder="${_s.placeholder || ''}"
          class="${_s.classNames.input}"
          role="textbox"
          aria-autocomplete="both"
          aria-multiline="${_s.mode == 'mix'}"
        ></span>&#8203;</tags>
      `;
    },

    tag (tagData) {
      const _s = this.settings;

      return `
        <tag
          ${tagData.readonly ? 'readonly' : ''}
          title="${tagData.title || tagData.value}"
          contenteditable="false"
          spellcheck="false"
          tabindex="${_s.a11y.focusableTags ? 0 : -1}"
          class="${_s.classNames.tag} ${tagData.class || ''}"
          style="white-space:nowrap;${tagData.style || ''}"
        >
          <x
            title=""
            class="${_s.classNames.tagX}"
            role="button"
            aria-label="remove tag"
          ></x>
          <div
            ${_s.mode != 'select' ? 'style="display:flex;align-items:center;"' : ''}
          >
            ${tagData.image ? `
              <img
                onerror="this.style.visibility='hidden'"
                src="${tagData.image}"
                style="height:var(--tag-img-size, 1em);margin-right:.3em;pointer-events:none;"
              >
            ` : ''}
            <span
              class="${_s.classNames.tagText}"
            >${tagData[_s.tagTextProp] || tagData.value}</span>
          </div>
        </tag>
      `;
    },

    dropdownItem (item) {
      const style = getComputedStyle(this.DOM.originalInput);
      const gap = style.getPropertyValue('--item-gap') || '.3em';
      const img_size = style.getPropertyValue('--item-img-size') || '1em';
      const text_color = style.getPropertyValue('--item-text-color') || 'inherit';
      const sec_color = style.getPropertyValue('--item-sec-color') || 'inherit';
      const sec_size = style.getPropertyValue('--items-sec-size') || '.75em';
      const count_color = style.getPropertyValue('--item_count-color') || 'inherit';

      return `
        <div
          value="${item.value}"
          class="${this.settings.classNames.dropdownItem} ${item.class || ''}"
          style="display:flex;align-items:center;gap:var(--item-gap, ${gap})"
          tabindex="0"
          role="option"
        >
          ${item.image ? `
            <img
              onerror="this.style.visibility='hidden'"
              src="${item.image}"
              style="height:var(--item-img-size, ${img_size});pointerevents:none"
            >
          ` : ''}
          <div style="color:var(--item-text-color, ${text_color})">
            ${item.label || item.value}
            ${item.secondary ? `
              <br>
              <span style="color:var(--item-sec-color, ${sec_color});font-size:${sec_size}">
                ${item.secondary}
              </span>
            ` : ''}
          </div>
          ${item.count ? `
            <div style="color:var(--item-count-color, ${count_color}">
              (${item.count})
            </div>
          ` : ''}
        </div>
      `;
    },
  },

  _setValue (value, defaultValue) {
    dmx.Component('form-element').prototype._setValue.call(this, value, defaultValue);
    this._tagify.loadOriginalValues(this.props.value);
    this._updateData();
  },

  _whitelist () {
    return Array.isArray(this.props.data) ? this.props.data.map(item => {
      const scope = dmx.DataScope(item, this);
      const tagData = {
        _dmx: true,
        _item: item,
        value: dmx.parse(this.props.tagValue, scope),
        label: dmx.parse(this.props.tagText, scope),
      };

      if (item.style) tagData.style = item.style;
      if (this.props.tagSecondary) tagData.secondary = dmx.parse(this.props.tagSecondary, scope);
      if (this.props.tagImage) tagData.image = dmx.parse(this.props.tagImage, scope);
      if (this.props.tagClass) tagData.class = dmx.parse(this.props.tagClass, scope);
      if (this.props.tagCount) tagData.count = dmx.parse(this.props.tagCount, scope);
      if (this.props.tagReadonly) tagData.readonly = !!dmx.parse(this.props.tagReadonly, scope);

      return tagData;
    }) : [];
  },

  _formdataHandler (event) {
    const formData = event.formData;
    let _name = this.$node.name;

    if (_name && this.props.mode != 'select' && this.props.mode != 'mix') {
      formData.delete(this.$node.name);
      
      if (Array.isArray(this.data.values)) {
        if (_name.slice(-2) != '[]') _name += '[]';
        this.data.values.forEach(value => {
          formData.append(_name, value);
        })
      }
    }
  },

  _resetHandler (event) {
    if (this._tagify) {
      if (this.$node.defaultValue) {
        this._setValue(this.$node.defaultValue);
        this._tagify.loadOriginalValues(this.$node.defaultValue);
      } else {
        this._tagify.removeAllTags();
      }

      this._updateData();
    }

    dmx.Component('form-element').prototype._resetHandler.call(this, event);
  },

});
